import { em, fluidRange } from 'polished';
import { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { fontFamilyWithFallback } from '../utils/fonts';
const createSizePairs = (arr) => {
  const result = [];

  for (let i = 0; i < arr.length - 1; i++) {
    const pair = {
      fromSize: arr[i],
      toSize: arr[i + 1],
    };
    result.push(pair);
  }

  return result;
};

const createDefaultHeadingVariant = (variantName, props) => {
  const variant = props.theme.heading[variantName];

  const variantStyles = `
    ${props.theme.helpers.fontFamilyWithFallback(variant.fontFamily)};
    color: ${variant.color};
  `;

  return css`
    ${!props.inheritSize &&
      props.theme.helpers.dynamicFontSize(props.fontSizes || variant.fontSizes[props.fontSizeIndex])};
    ${variantStyles}
  `;
};

const createDefaultTextVariant = (variantName, props) => {
  const variant = props.theme.text[variantName];

  const variantStyles = `
    ${props.theme.helpers.fontFamilyWithFallback(variant.fontFamily)};
    color: ${variant.color};
  `;

  return css`
    ${!props.inheritSize &&
      props.theme.helpers.dynamicFontSize(props.fontSizes || variant.fontSizes[props.fontSizeIndex])};
    ${variantStyles}
  `;
};

const createDefaultBulletListVariant = (variantName, props) => {
  const variant = props.theme.bulletList[variantName];

  const variantStyles = css`
    & > li {
      color: ${variant.item.color};
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      & + li {
        margin-top: ${(p) => !p.horizontal && '1em'};
      }

      &:before {
        content: "${variant?.bullet && variant.bullet.unicode}";
        color: ${variant?.bullet && variant.bullet.color};
        margin: 0 1em;
        flex-shrink: 1;
        line-height: 1em;
      }
    }

    ${up('tablet')} {
      display: ${(p) => p.horizontal && 'flex'};
    }
  `;

  return variantStyles;
};

const createDefaultLinkVariant = (variantName, props) => {
  const variant = props.theme.link[variantName];

  const variantStyles = css`
    ${props.theme.helpers.fontFamilyWithFallback(variant.fontFamily)};
    color: ${variant.color.default};

    &:hover,
    &:focus,
    &.focus {
      color: ${variant.color.hover};
    }

    &:active,
    &.active {
      color: ${variant.color.active};
    }
  `;

  return variantStyles;
};

const createDefaultInputOrTextareaVariant = (variantName, p, themeProperty = 'input', themeElement = 'input') => {
  const variant = p.theme[themeProperty][variantName];

  const variantStyles = css`
    &.is-required {
    }

    &.is-invalid {
    }

    &.is-confirmed {
    }

    &:focus-within {
      .inputBox {
        background: ${variant.bg[[`${themeElement}Focused`]]};
      }

      .input {
        color: ${variant.color[`${themeElement}Focused`]};
      }
    }

    .labelBox {
      ${p.theme.helpers.dynamicFontSize(variant.fontSizes.label)};
      font-size: ${variant.fontFamily.label};
      color: ${variant.color.label};
      background: ${variant.bg.label};
    }

    .inputBox {
      ${p.theme.helpers.dynamicFontSize(variant.fontSizes[themeElement])};
      ${p.theme.helpers.fontFamilyWithFallback(variant.fontFamily[themeElement])};
      color: ${variant.color[themeElement]};
      background: ${variant.bg[[themeElement]]};
    }

    .input {
      /* NOTE: Fixes for Firefox autocomplete  */
      filter: none;

      &::placeholder {
        color: ${variant.color.placeholder};
      }

      /* NOTE: Fixes for Webkit autocomplete  */
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:active {
        -webkit-box-shadow: inset 0 0 0px 9999px ${variant.bg[[themeElement]]};
      }

      &:-webkit-autofill:focus {
        -webkit-box-shadow: inset 0 0 0px 9999px ${variant.bg[[`${themeElement}Focused`]]};
      }
    }

    .icon {
      color: ${variant.color.icon};
      background: ${variant.bg.icon};
    }

    .clearBox {
    }

    .clear {
      color: ${variant.color.clear};
      background: ${variant.bg.clear};
    }

    .errorBox {
      ${p.theme.helpers.dynamicFontSize(variant.fontSizes.error)};
      ${p.theme.helpers.fontFamilyWithFallback(variant.fontFamily.error)};
      color: ${variant.color.error};
      background: ${variant.bg.error};
    }

    .error {
    }
  `;

  return variantStyles;
};

const createDefaultCheckboxVariant = (variantName, props) => {
  const variant = props.theme.checkbox[variantName];

  const variantStyles = css`
    ${props.theme.helpers.fontFamilyWithFallback(variant.fontFamily.label)};

    .labelBox{
      margin-bottom: ${props.radio ? '0.5em' : '1em'};
      line-height: ${props.radio && '125%'};
    }

    .descriptionBox{
      display: flex;

      > .description {
        margin-left: ${variant.ratio.margin}em;
        color: ${variant.color.label};
      }

      &:before, &:after {
        display: inline-block;
        content: '';
        min-width: 1em;
        min-height: 1em;
        height: 1em;
        width: 1em;
        border-radius: 2px;
      }

      &:before {
        margin: ${em(variant.ratio.topDistance)} 0em;
      }

      &:after {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        transform-origin: left center;
        
        position: absolute;
        top: ${em(variant.ratio.topDistance)};
        
      }
    }

    
    input[type="checkbox"] ~ .descriptionBox {
      &:before {
        border: 1px solid ${variant.color.border};
      }
    }

    &.isHighlighted {
      input[type="checkbox"] ~ .descriptionBox {
        &:after {
          background-image: url('${variant.bgSrc.highlighted}');
        }
      }
    }

    input[type="checkbox"]:checked ~ .descriptionBox {
      &:after {
        background-image: url('${variant.bgSrc.checked}');
      }
    }

    input[type="radio"] ~ .descriptionBox {
      &:after {
        background-image: url('${variant.bgSrc.default}');
      }
    }

    input[type="radio"]:checked ~ .descriptionBox {
      &:after {
        background-image: url('${variant.bgSrc.checked}');
      }
    }

    input:disabled ~ .descriptionBox {
      &:after {
        background-image: url('${variant.bgSrc.disabled}');
      }
    }

    input:checked:disabled ~ .descriptionBox {
      &:after {
        background-image: ${({ disabledInvisible }) =>
          disabledInvisible
            ? `url('${variant.bgSrc.disabledCheckedInvisible}')`
            : `url(${variant.bgSrc.disabledChecked})`};
        cursor: ${({ disabledInvisible }) => (disabledInvisible ? 'no-drop' : 'auto')};
      }
    }

    input[type="checkbox"]:not(:disabled) ~ .descriptionBox:hover {
      cursor: pointer;
    }

    input[type="radio"]:not(:disabled):not(:checked) ~ .descriptionBox:hover {
      cursor: pointer;
    }

    .error {
      color: ${variant.color.error};
    }
  `;

  return variantStyles;
};

const createCSSPropertyStaticRange = (property, sizes, breakpoints) => {
  const createPropDeclarationLine = (bpKey) => `${property}: ${sizes[bpKey]};`;

  return css`
    ${Object.entries(breakpoints).map(
      ([bp, size]) => css`
        @media (min-width: ${em(size)}) {
          ${createPropDeclarationLine(bp)};
        }
      `,
    )}
  `;
};

const hexToRGBA = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

const createCSSPropertyFluidRange = (property, sizes, breakpoints) => {
  const bpKeys = Object.keys(breakpoints);
  const sizePairs = createSizePairs(bpKeys);

  return css`
    ${sizePairs.map(
      ({ fromSize, toSize }) => css`
        ${fluidRange(
          {
            prop: property,
            fromSize: sizes[fromSize],
            toSize: sizes[toSize],
          },
          `${breakpoints[fromSize]}px`,
          `${breakpoints[toSize]}px`,
        )};
      `,
    )}
  `;
};

const createTransition = ({ durations, easings, delays }) => (
  property = 'all',
  durationIndex = 0,
  easingKey = 'default',
  delayIndex = 0,
) => {
  const ensurePropIsArray = (p) => (Array.isArray(p) ? p : [p]);
  const getPropConfigValue = (p, conf, idx) => p[conf[idx]] || p[conf];
  const themeConfig = [durations, easings, delays];
  const transitions = [property, durationIndex, easingKey, delayIndex].map(ensurePropIsArray);
  const cssTransitionPropValue = transitions[0].reduce((cssProp, prop, propIndex) => {
    const [duration, easing, delay] = transitions
      .slice(1)
      .map((cfg, idx) => getPropConfigValue(themeConfig[idx], cfg, propIndex));
    return `${cssProp} ${prop} ${duration} ${easing} ${delay},`;
  }, '');

  return css`
    transition: ${cssTransitionPropValue.slice(0, -1).concat(';')};
  `;
};

export const withHelpers = (theme) => ({
  ...theme,
  helpers: {
    /* Shorthand for creating initial styles for Heading component's variant */
    createDefaultHeadingVariant,

    /* Shorthand for creating initial styles for Text component's variant */
    createDefaultTextVariant,

    /* Shorthand for creating initial styles for BulletList component's variant */
    createDefaultBulletListVariant,

    /* Shorthand for creating initial styles for Link component's variant */
    createDefaultLinkVariant,

    /* Shorthand for creating initial styles for Input component's variant */
    createDefaultInputVariant: (variantName, props) =>
      createDefaultInputOrTextareaVariant(variantName, props, 'input', 'input'),

    /* Shorthand for creating initial styles for Textarea component's variant */
    createDefaultTextareaVariant: (variantName, props) =>
      createDefaultInputOrTextareaVariant(variantName, props, 'textarea', 'textarea'),

    /* Shorthand for creating initial styles for Checkbox component's variant */
    createDefaultCheckboxVariant,

    /* Shorthand for applying responsive font sizes from theme */
    dynamicFontSize: (sizes, fluid = true) => {
      const createDynammicFontSize = fluid ? createCSSPropertyFluidRange : createCSSPropertyStaticRange;
      return createDynammicFontSize('font-size', sizes, theme.breakpoints);
    },

    /* Sets font-family with fallback font with async swap */
    fontFamilyWithFallback,

    /* Shorthand for using transitions with theme config applied */
    transition: createTransition(theme.transitions),
    hexToRGBA,
  },
});

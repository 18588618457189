export const orderStatusKeys = {
  UNREGISTERED: 'unregistered',
  UNPAID: 'unpaid',
  PAID: 'paid',
};

export const orderStatusMap = new Map([
  [0, orderStatusKeys.UNREGISTERED],
  [1, orderStatusKeys.UNPAID],
  [2, orderStatusKeys.PAID],
]);

export const orderStatus = {
  WAITING_PAYMENT_INDEX: 1,
  WITH_ERRORS_WAITING_FOR_ERP_INDEX: 3,
  DELIVERED_INDEX: 8,
  END_INDEX: 9,
}

export const orderStatusStyleKey = {
  SUCCESS: 'success',
  DEFAULT: 'default',
  DANGER: 'danger',
}

export const mapStatusCodeToStyle = new Map([
  [orderStatus.DELIVERED_INDEX, orderStatusStyleKey.SUCCESS],
  [orderStatus.END_INDEX, orderStatusStyleKey.SUCCESS],
  [orderStatus.WAITING_PAYMENT_INDEX, orderStatusStyleKey.DANGER],
  [orderStatus.WITH_ERRORS_WAITING_FOR_ERP_INDEX, orderStatusStyleKey.DANGER],
])

import { rem } from 'polished';

export const type = {
  primary: '"Skatta Sans Regular", "Calibri Light", Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif',
  secondary: '"Skatta Sans Medium", Verdana, Geneva, sans-serif',
  tertiary: '"Skatta Sans Bold", Verdana, Geneva, sans-serif',
};

export const size = {
  xs3: '10px',
  s1: '12px',
  s2: '14px',
  s3: '16px',
  m1: '18px',
  m2: '20px',
  m3: '22px',
  m4: '24px',
  l1: '30px',
  l2: '40px',
  l3: '48px',
};

export const fontSize = {
  xs3: rem(10),
  s1: rem(12),
  s2: rem(14),
  s3: rem(16),
  m1: rem(18),
  m2: rem(20),
  m3: rem(22),
  m4: rem(24),
  m5: rem(26),
  m6: rem(28),
  l1: rem(30),
  l2: rem(40),
  l3: rem(48),
};

export const sizesSet = {
  r1: {
    mobile: size.s2,
    tablet: size.s2,
    desktop: size.s3,
    desktopLarge: size.s3,
    hd: size.s3,
  },
  r2: {
    mobile: size.l1,
    tablet: size.l2,
    desktop: size.l3,
    desktopLarge: size.l3,
    hd: size.l3,
  },
  r3: {
    mobile: size.s3,
    tablet: size.m2,
    desktop: size.l1,
    desktopLarge: size.l1,
    hd: size.l1,
  },
  r4: {
    mobile: size.s2,
    tablet: size.m1,
    desktop: size.m4,
    desktopLarge: size.m4,
    hd: size.m4,
  },
  r5: {
    mobile: size.s1,
    tablet: size.m2,
    desktop: size.l1,
    desktopLarge: size.l1,
    hd: size.l1,
  },
  r6: {
    mobile: size.s1,
    tablet: size.s1,
    desktop: size.s1,
    desktopLarge: size.s1,
    hd: size.s1,
  },
  r7: {
    mobile: size.s2,
    tablet: size.s2,
    desktop: size.s2,
    desktopLarge: size.s2,
    hd: size.s2,
  },
  r8: {
    mobile: size.s1,
    tablet: size.s1,
    desktop: size.s2,
    desktopLarge: size.s2,
    hd: size.s2,
  },
  r9: {
    mobile: size.s3,
    tablet: size.m1,
    desktop: size.m2,
    desktopLarge: size.m2,
    hd: size.m2,
  },
  r10: {
    mobile: size.s2,
    tablet: size.s3,
    desktop: size.m1,
    desktopLarge: size.m1,
    hd: size.m1,
  },
  r11: {
    mobile: size.s1,
    tablet: size.s2,
    desktop: size.m1,
    desktopLarge: size.m1,
    hd: size.m1,
  },
  r12: {
    mobile: size.s1,
    tablet: size.s1,
    desktop: size.s3,
    desktopLarge: size.s3,
    hd: size.s3,
  },
  r13: {
    mobile: size.m2,
    tablet: size.m2,
    desktop: size.m4,
    desktopLarge: size.m4,
    hd: size.m4,
  },
  r14: {
    mobile: size.m4,
    tablet: size.l1,
    desktop: size.l2,
    desktopLarge: size.l2,
    hd: size.l2,
  },
  r15: {
    mobile: size.s3,
    tablet: size.s3,
    desktop: size.s2,
    desktopLarge: size.s2,
    hd: size.s2,
  },
  r16: {
    mobile: size.m2,
    tablet: size.m2,
    desktop: size.m2,
    desktopLarge: size.m2,
    hd: size.m2,
  },
  r17: {
    mobile: size.s3,
    tablet: size.s3,
    desktop: size.m1,
    desktopLarge: size.m1,
    hd: size.m1,
  },
  r18: {
    mobile: size.m1,
    tablet: size.m1,
    desktop: size.m1,
    desktopLarge: size.m1,
    hd: size.m1,
  },
  r19: {
    mobile: size.s3,
    tablet: size.s3,
    desktop: size.s3,
    desktopLarge: size.s3,
    hd: size.s3,
  },
  r20: {
    mobile: size.l1,
    tablet: size.m2,
    desktop: size.m2,
    desktopLarge: size.m2,
    hd: size.m2,
  },
  r21: {
    mobile: size.s2,
    tablet: size.m1,
    desktop: size.m2,
    desktopLarge: size.m2,
    hd: size.m2,
  },
  r22: {
    mobile: size.m3,
    tablet: size.m3,
    desktop: size.m1,
    desktopLarge: size.m1,
    hd: size.m1,
  },
  r23: {
    mobile: size.m1,
    tablet: size.m1,
    desktop: size.s3,
    desktopLarge: size.s3,
    hd: size.s3,
  },
};

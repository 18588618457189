import { createContext } from 'react';

export const CookieContext = createContext({
  cookies: {},
  setCookie: () => {
    // eslint-disable-next-line no-console
    console.warn('CookieContext has no provider over him');
  },
});

CookieContext.displayName = 'CookieContext';

export const spacing = {
  0: '0',
  1: '4px',
  2: '8px',
  3: '16px',
  4: '24px',
  5: '40px',
  6: '48px',
  7: '80px',
};

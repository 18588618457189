import Progress from 'rsup-progress';

const progressBar =
  typeof window !== 'undefined'
    ? new Progress({
        className: 'rsup-progress',
      })
    : {
        start: () => {},
        end: () => {},
      };

export default progressBar;

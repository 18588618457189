const dayjs = require('dayjs');
const { localesEnum, availableLocales } = require('./languages.config');

const localeCookieName = '_luid';
const localeCookieExpirationDate = dayjs()
  .add(1, 'year')
  .toDate();

const availableLanguages = availableLocales.map(({ locale }) => locale);
const availableNamespaces = [
  'global',
  'account',
  'basket',
  'errors',
  'inputlabels',
  'searchfilter',
  'tablestitle',
  'tooltips',
  'clipboard',
  'customerStructure',
  'productreviews',
  'complaints',
  'returnspage',
  'returnsandcomplaints',
];

const defaultLocale = availableLocales[0];
const defaultNamespace = availableNamespaces[0];
const defaultLanguage = defaultLocale?.locale;
const otherLanguages = availableLanguages.filter((language) => language !== defaultLanguage);

module.exports = {
  localesEnum,
  availableLanguages,
  availableLocales,
  availableNamespaces,
  defaultLanguage,
  defaultLocale,
  otherLanguages,
  defaultNamespace,
  localeCookieName,
  localeCookieExpirationDate,
};

export const offerStatus = {
    SENDED: 0,
    IN_PROGRESS: 1,
    READY: 2,
    REALIZED: 3,
    ACCEPTED_BY_CUSTOMER: 4,
    REJECTED_BY_CUSTOMER: 5,
    EXPIRED: 6,
 }
 
 export const offerStatusStyleKey = {
   SUCCESS: 'success',
   DEFAULT: 'default',
   DANGER: 'danger',
 }
 
 
 export const mapOfferStatusCodeToStyle = new Map([
   [offerStatus.READY, offerStatusStyleKey.SUCCESS],
   [offerStatus.ACCEPTED_BY_CUSTOMER, offerStatusStyleKey.SUCCESS],
   [offerStatus.REJECTED_BY_CUSTOMER, offerStatusStyleKey.DANGER],
 ])
export const defaultPalette = {
  prussianBlue: ['#002855', '#55a5ff', '#a7d0ff', '#001936', '#bde4ff', '#7091AD'],
  teal: ['#00857e', '#00bfb3', '#02e3d5', '#16857E'],
  blazeOrange: ['#e35e00', '#ffb900', '#ffd200', '#ff6900', '#e45e00'],
  alizarinCrimson: ['#e4212f', '#d1232f'],
  chateauGreen: ['#2d9b4b'],
  pastelPink: ['#ffd2da'],
  sandYellow: ['#fff0aa', '#fffdea'],
  monochrome: [
    '#fff',
    '#eaeaea',
    '#e2e2e2',
    '#8e8e8e',
    '#333',
    '#000',
    '#f5f5f5',
    '#747474',
    '#CCC',
    '#f8f9fa',
    '#CDCDCD',
    '#f8f9fab3',
  ],
  paleYellow: ['#fffde9'],
  saddleBrown: ['#8B4513'],
};

const localesEnum = {
  polish: 'pl',
  english: 'en',
  ukrainian: 'uk',
  czech: 'cs',
  slovak: 'sk',
  romanian: 'ro',
  serbian: 'sr',
  german: 'de',
  hungarian: 'hu',
};

const availableLocales = [
  {
    locale: 'pl',
    name: 'Polski',
  },
  {
    locale: 'en',
    name: 'English',
  },
  {
    locale: 'uk',
    name: 'Українська',
  },
  {
    locale: 'cs',
    name: 'Čeština',
  },
  {
    locale: 'sk',
    name: 'Slovenský',
  },
  {
    locale: 'ro',
    name: 'Română',
  },
  {
    locale: 'sr',
    name: 'Srpski',
  },
  {
    locale: 'de',
    name: 'Deutsch',
  },
  {
    locale: 'hu',
    name: 'Magyar',
  },
];

module.exports = {
  localesEnum,
  availableLocales,
};

// Czeski: cs_CZ onninen.pl/cs/
// Słowacki: sk_SK onninen.pl/sk/
// Rumuński: ro_RO onninen.pl/ro/

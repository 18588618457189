import reset from 'styled-reset';
import { rem } from 'polished';
import { createGlobalStyle } from 'styled-components';
import { defaultTheme } from './themes';

export const GlobalStyle = createGlobalStyle`
  ${reset}

  @media print { 
    @page { 
      margin: 15mm 10mm 15mm 10mm;
    }

    .no-print, .no-print * {
      display: none !important;
    }

    .print-only {
      display: block !important;
    }
  }

  ::selection {
    background: ${defaultTheme.global.colorAlt};
    color: ${defaultTheme.global.bg};
  }

  ::-moz-selection {
    background: ${defaultTheme.global.colorAlt};
    color: ${defaultTheme.global.bg};
  }

  *:focus {
    outline: none;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  html, body {
    min-height: 100%;
    overflow-x: hidden;
  }

  html {
    overflow-y: auto !important;
  }

  html.hideScroll {
    overflow-y: hidden !important;
    @media only screen and (min-width: 1440px) {
      padding-right: 15px;
}
    

  }

  body {
    ${defaultTheme.helpers.fontFamilyWithFallback(defaultTheme.global.fontFamily.primary)};
    ${defaultTheme.helpers.dynamicFontSize(defaultTheme.global.fontSizes[0])};
    line-height: 1.25;
    overflow-y: hidden !important;

    #__next {
      display: flex;
      flex-direction: column;
      min-height: 100vh;

      & > * {
        width: 100%;
      }
    }

    &:not(.sb-show-main) {
      background: ${defaultTheme.global.bg};
      color: ${defaultTheme.global.color};
    }
  }

  button {
    padding: 0;
    border: none;
    background: none;
    font-family: inherit;
  }

  b, strong {
    ${defaultTheme.helpers.fontFamilyWithFallback(defaultTheme.global.fontFamily.tertiary)};
  }

  em {
    font-style: italic;
  }

  small {
    font-size: 0.75em;
  }

  sub, sup {
    font-size: 0.75em;
  }

  sub {
    vertical-align: sub;
  }

  sup {
    vertical-align: super;
  }

  a {
    color: inherit;
  }

  img {
    color: transparent;

    &[decoding="async"] {
      animation: ${defaultTheme.animations.fadeIn} ${defaultTheme.transitions.durations[0]} 1;
    }
  }

  /* Vendor elements' classes */
  .rsup-progress {
    background: ${defaultTheme.global.colorAlt2} !important;
    top: 0 !important;
    z-index: 99999991 !important;
  }




  .simplebar-scrollbar::before {
    
    background-color: ${defaultTheme.defaultPalette.teal[0]};
    opacity: 1 !important;
    border-radius: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .simplebar-vertical {
    background-color: ${defaultTheme.defaultPalette.monochrome[2]} !important;
    width: ${rem(6)} !important;
  }

  /* Helper classes */
  .main {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    padding-top: ${rem(defaultTheme.spacing[4])};
    padding-bottom: ${rem(defaultTheme.spacing[4])};

    &.basket {
      padding-top: ${rem(defaultTheme.spacing[2])};
      padding-bottom: ${rem(defaultTheme.spacing[2])};

      @media screen and (min-width: 46.0625em) {
        padding-top: ${rem(defaultTheme.spacing[3])};
        padding-bottom: ${rem(defaultTheme.spacing[3])};
      }
    }

    @media print {
      padding-top: 0;
      padding-bottom: 0;
    }

    @media screen and (min-width: 46.0625em) {
      padding-top: ${rem(defaultTheme.spacing[4])};
      padding-bottom: ${rem(defaultTheme.spacing[5])};
    }
  }

  .wrapper {
    max-width: ${defaultTheme.global.maxWidth};
    padding-left: ${rem(defaultTheme.spacing[3])};
    padding-right: ${rem(defaultTheme.spacing[3])};
    margin: 0 auto;

    @media print {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .basket-main {
    padding-bottom: ${rem(defaultTheme.spacing[2])};

    @media screen and (min-width: 46.0625em) {
      padding-bottom: ${rem(defaultTheme.spacing[5])};
    }
  }

  .color-alt {
    color: ${defaultTheme.global.colorAlt};
  }

  .text-lowercase {
    text-transform: lowercase;
  }
  
  .hidden {
    display: none;
  }

  .pac-container {
    z-index: 999999999999!important;
  }
`;

import clientCookieManager from 'js-cookie';
import selectedCookiesDict from '../../config/selectedCookiesDict';

export const getSelectedCookies = (req) => {
  const serverSideCookies = req?.cookies;
  const prepareCookie = {};

  if (serverSideCookies) {
    Object.keys(serverSideCookies).forEach((cookieName) => {
      if (selectedCookiesDict.includes(cookieName)) {
        prepareCookie[cookieName] = serverSideCookies[cookieName];
      }
    });
  } else if (document) {
    selectedCookiesDict.forEach((cookieName) => {
      prepareCookie[cookieName] = clientCookieManager.get(cookieName);
    });
  } else {
    // eslint-disable-next-line no-console
    console.warn("Can't get cookies");
  }

  return prepareCookie;
};

import { clientTypes } from 'src/enums/clientsTypes';

export const createGoogleTagManagerScript = (gtmId) =>
  `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${gtmId}');
  `
    .trim()
    .replace(/(\r\n|\n|\r)/gm, '');

export const createGoogleTagManagerIFrame = (gtmId) =>
  `
  <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `
    .trim()
    .replace(/(\r\n|\n|\r)/gm, '');

export const pushEvent = (eventName, eventPayload = {}) => {
  const gtmEvent = {
    event: eventName,
    ...eventPayload,
  };

  if (!window?.dataLayer) {
    window.dataLayer = [];
  }

  if (window?.dataLayer != null) {
    window.dataLayer.push(gtmEvent);
  }

  return gtmEvent;
};

export const addOriginalLocationToDataLayer = `window.dataLayer = window.dataLayer || []
window.dataLayer.push({
  originalLocation:
    document.location.protocol +
    '//' +
    document.location.hostname +
    document.location.pathname +
    document.location.search,
});`;

export const compileObjectUserToAddToAnalytics = (user) => {
  const isUserLoggedIn = !!user;

  if (isUserLoggedIn) {
    return `window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      "event": "eec.user",
      "customerId": ${user.currentCustomer.id},
      "loginStatus": "zalogowany",
      "userType": "${clientTypes[user.clientType]}",
      "visitorId": null,
      "customerOrderCount": null,
      customerTotalSpent: null
    });`;
  }
  return `window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    "event": "eec.user",
    "visitorId": null,
    "customerId": null,
    "loginStatus": "wylogowany",
    "userType": null,
    "customerOrderCount": null,
    "customerTotalSpent": null
  })`;
};

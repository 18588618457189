import 'intersection-observer';
import 'core-js/stable/promise/finally';
import App from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useLayoutEffect } from 'react';
import { GlobalStyle } from 'src/styles/global';
import i18nNextInstance from 'setup/i18nNextInstance';
import { getSelectedCookies } from 'src/utils/getSelectedCookies';
import useCachedScrollPositions from 'src/hooks/useCachedScrollPositions';
import usePageLoadProgressBar from 'src/hooks/usePageLoadProgressBar';
import useSmoothHashScroll from 'src/hooks/useSmoothHashScroll';
import { CookiesProvider } from 'src/providers/cookie/CookiesProvider';
import { ClipboardProvider } from 'src/providers/clipboard';
import { addOriginalLocationToDataLayer, compileObjectUserToAddToAnalytics, pushEvent } from 'src/utils/gtm';

const EnhancedApp = ({ Component, pageProps, cookies }) => {
  const { events } = useRouter();
  useCachedScrollPositions();
  usePageLoadProgressBar();
  useSmoothHashScroll();

  useEffect(() => {
    // NOTE: Polyfills window.crypto for NanoID package
    if (!window.crypto) {
      window.crypto = window.msCrypto;
    }
  });

  useEffect(() => {
    const requestPermission = () => {
      if (typeof window !== 'undefined' && 'Notification' in window) {
        setTimeout(() => {
          Notification.requestPermission();
        }, 2000);
      }
    };

    requestPermission();
  }, []);

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      events.on('routeChangeComplete', (page) => pushEvent('pageview', { page, title: window.document.title }));
    }
    return () => {
      events.off('routeChangeComplete', (page) => pushEvent('pageview', { page, title: window.document.title }));
    };
    /* eslint-disable-next-line */
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

        <script
          dangerouslySetInnerHTML={{
            __html: addOriginalLocationToDataLayer,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: compileObjectUserToAddToAnalytics(pageProps.user),
          }}
        />

        {process.env.gtmId ? (
          <script
            id="google-analytics"
            dangerouslySetInnerHTML={{
              __html: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.gtmId}');
        `,
            }}
          ></script>
        ) : null}
      </Head>
      <GlobalStyle />

      <noscript>
        <style dangerouslySetInnerHTML={{ __html: '.offerCarousel, .loadingCoverWrapper { height: auto; }' }} />
        <style dangerouslySetInnerHTML={{ __html: '.loadingCover { display: none; }' }} />
        <style dangerouslySetInnerHTML={{ __html: '.loadingCoverWrapper { padding-bottom: 0; }' }} />
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.keen-slider { display: flex; flex-wrap: wrap; } ' +
              '.keen-slider > .keen-slider__slide { flex: 0 1 50%; margin-bottom: 2em; }' +
              '.slider-wrapper > .arrow { display: none; }',
          }}
        />
      </noscript>

      <CookiesProvider cookies={cookies}>
        <ClipboardProvider>
          <Component {...pageProps} />
        </ClipboardProvider>
      </CookiesProvider>
    </>
  );
};

EnhancedApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  appProps.cookies = getSelectedCookies(appContext?.ctx?.req);

  return appProps;
};

export default i18nNextInstance.appWithTranslation(EnhancedApp);

export const breakpoints = {
  /* Targeting all devices */
  mobile: 1,
  /* Targeting devices that are larger than the iPhone 6 Plus (which is 736px in landscape mode) */
  tablet: 737,
  /* Targeting devices that are larger than the iPad (which is 1024px in landscape mode) */
  desktop: 1025,
  /* Targeting devices that are larger than the desktop (which is 1200px in landscape mode) */
  desktopLarge: 1200,
  /** Targeting devices that have HD-ready resolution */
  hd: 1280,
};

export const mediaQueries = {
  mobile: `(min-width: ${breakpoints.mobile}px) and (max-width: ${breakpoints.tablet - 1}px)`,
  tablet: `(min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.desktop - 1}px)`,
  desktop: `(min-width: ${breakpoints.desktop}px) and (max-width: ${breakpoints.hd - 1}px)`,
  desktopLarge: `(min-width: ${breakpoints.desktopLarge}px) and (max-width: ${breakpoints.desktopLarge - 1}px)`,
  hd: `(min-width: ${breakpoints.hd}px)`,
};

const dayjs = require('dayjs');
const path = require('path');
const {
  availableLanguages,
  availableNamespaces,
  defaultLanguage,
  defaultNamespace,
  otherLanguages,
  localeCookieName,
  localeCookieExpirationDate,
} = require('./locales');

const cookieExpirationMinutes = Math.abs(dayjs().diff(localeCookieExpirationDate, 'minute'));

module.exports = {
  defaultLanguage,
  fallbackLng: defaultLanguage,
  defaultNS: defaultNamespace,
  fallbackNS: defaultNamespace,
  ns: availableNamespaces,
  localePath: path.resolve(process.cwd(), 'public/static/locales'),
  otherLanguages,
  whitelist: availableLanguages,
  react: {
    wait: true,
    useSuspense: false,
  },
  detection: {
    lookupCookie: localeCookieName,
    caches: ['cookie'],
    cookieSameSite: 'strict',
    order: ['cookie'],
  },
};

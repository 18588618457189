import { useRouter } from 'next/router';
import { useEffect } from 'react';

const scrollBehaviorPropertyName = 'scroll-behavior';
const smoothScrollBehaviorCSS = [scrollBehaviorPropertyName, 'smooth', 'important'];

export const useSmoothHashScroll = () => {
  const { events } = useRouter();

  useEffect(() => {
    const html = document.documentElement;
    html.style.setProperty(...smoothScrollBehaviorCSS);

    events.on('routeChangeStart', () => {
      html.style.removeProperty(scrollBehaviorPropertyName);
    });

    events.on('routeChangeComplete', () => {
      html.style.setProperty(...smoothScrollBehaviorCSS);
    });
    /* eslint-disable-next-line */
  }, []);
};

import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useCachedScrollPositions = () => {
  const { events, beforePopState } = useRouter();

  useEffect(() => {
    let cachedScrollPositions = null;
    let shouldScrollRestore = null;

    window.history.scrollRestoration = 'manual';

    events.on('routeChangeStart', () => {
      cachedScrollPositions = { x: window.scrollX, y: window.scrollY };
    });

    events.on('routeChangeComplete', () => {
      if (!shouldScrollRestore) return;

      const { x, y } = shouldScrollRestore;
      window.scrollTo(x, y);
      shouldScrollRestore = null;
    });

    beforePopState(() => {
      if (cachedScrollPositions) {
        const { x, y } = cachedScrollPositions;
        shouldScrollRestore = { x, y };
      }

      return true;
    });
    /* eslint-disable-next-line */
  }, []);
};

export const clientTypesKeys = {
  CLIENT_B2B: 'clientB2B',
  CLIENT_B2C: 'clientB2C',
};

export const clientTypesMap = new Map([
  [0, clientTypesKeys.CLIENT_B2C],
  [1, clientTypesKeys.CLIENT_B2B],
  [clientTypesKeys.CLIENT_B2C, 0],
  [clientTypesKeys.CLIENT_B2B, 1],
]);

export const clientTypes = ['B2C', 'B2B'];

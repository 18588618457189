export const priceColorsKeys = {
  NORMAL: 'normal',
  PROMOTIONS: 'promotions',
  SALE: 'sale',
  AWARDED: 'awarded',
  EXTRA_DISCOUNT: 'extraDiscount',
  DEFAULT: 'default',
};

export const priceColorsMap = new Map([
  [0, priceColorsKeys.NORMAL],
  [1, priceColorsKeys.PROMOTIONS],
  [2, priceColorsKeys.SALE],
  [3, priceColorsKeys.AWARDED],
  [4, priceColorsKeys.EXTRA_DISCOUNT],
]);

export const contactStatusKeys = {
  UNKNOWN: 'Unknown',
  AVAILABLE: 'Available',
  HOLIDAY: 'Holiday',
  DISEASE: 'Disease',
  NOT_WORK: 'NotWork',
};

export const contactStatusMap = new Map([
  [0, contactStatusKeys.UNKNOWN],
  [1, contactStatusKeys.AVAILABLE],
  [2, contactStatusKeys.HOLIDAY],
  [3, contactStatusKeys.DISEASE],
  [4, contactStatusKeys.NOT_WORK],
]);

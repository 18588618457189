import { createContext } from 'react';

export const ClipboardContext = createContext({
  clipboardInfo: null,
  setClipboardInfo: () => {
    // eslint-disable-next-line no-console
    console.warn('ClipboardContext has no provider over him');
  },
});

ClipboardContext.displayName = 'ClipboardContext';

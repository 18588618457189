import { keyframes } from 'styled-components';

export const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const fadeIn = keyframes`
  0% { 
    opacity: 0;
  }
  100% { 
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  0% { 
    opacity: 1;
  }
  100% { 
    opacity: 0;
  }
`;

export const scaleUpWithFade = keyframes`
  0% { 
    opacity: 0;
    transform: scale(0);
  }
  100% { 
    opacity: 1;
    transform: scale(1);
  }
`;

export const scaleDownWithFade = keyframes`
  0% { 
    opacity: 1;
    transform: scale(1);
  }
  50% { 
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
`;

export const glow = keyframes`
  0%, 100% { 
    opacity: 1;
  }
  50% { 
    opacity: .4;
  }
`;

export const float = keyframes`
  0% { 
    transform: translateY(1px);
  }
  25% { 
    transform: translateY(0px);
  }
  50% { 
    transform: translateY(-3px);
  }
  100% { 
    transform: translateY(1px);
  }
`;

export const jiggle = keyframes`
  0%, 100% { 
    transform: translate3d(0,0,0);
  }
  12.5%, 62.5% { 
    transform: translate3d(-4px,0,0);
  }
  37.5%, 87.5% { 
    transform: translate3d(4px,0,0);
  }
`;

export const moveBackground = keyframes`
  0% {
  background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
`;

import FontFaceObserver from 'fontfaceobserver';
import { fontFace } from 'polished';
import { css } from 'styled-components';
import { contentGridQuartersModifier } from 'src/enums/contentGridQuartersModifier';

export const fontNameToClassName = (fontFamily) =>
  fontFamily
    .replace(/ /gim, '-')
    .replace(/["']/gim, '')
    .toLowerCase();

export const getPrimaryFontFamily = (fontFamily) => `${fontFamily.split(',').slice(0, 1)[0]}`;

export const getFallbackFontFamily = (fontFamily) =>
  `${fontFamily
    .split(',')
    .slice(1)
    .join(',')}`;

const createFontFaceString = ({ family, path }) => {
  const fontFaceDefinitionArray = fontFace({
    fontFamily: family,
    fontFilePath: path,
    fontDisplay: 'block',
    fileFormats: ['woff2', 'woff', 'eot', 'ttf', 'svg'],
  });

  return `${css(fontFaceDefinitionArray)
    .join('')
    /* eslint-disable-next-line */
    .replace(/\"/gim, '')}`;
};

export const loadFontAsynchronously = async (font) => {
  const { family } = font;
  const link = document.createElement('style');

  link.textContent = createFontFaceString(font);
  document.head.appendChild(link);
  await new FontFaceObserver(family).load();
  document.documentElement.classList.add(fontNameToClassName(family));
};

export const loadFontsAsynchronously = (fontsList) => {
  fontsList.forEach((font) => {
    loadFontAsynchronously(font);
  });
};

export const createFontFaceStyles = (fontsList) => {
  let fontFaceCSS = '';

  fontsList.forEach((font) => {
    fontFaceCSS += createFontFaceString(font);
  });

  return fontFaceCSS;
};

export const createFontsDocumentClassNamesString = (fontsList) => {
  let classNamesString = '';

  fontsList.forEach(({ family }) => {
    classNamesString += `${fontNameToClassName(family)} `;
  });

  return classNamesString.trim();
};

export const fontFamilyWithFallback = (fontFamily) => `
  font-family: ${getFallbackFontFamily(fontFamily)}, sans-serif;
  font-family: ${getPrimaryFontFamily(fontFamily)}, sans-serif;
`;

export const determineFontSize = (columnsByClassName, slidesPerView) => {
  // Values are based on ONNINB2B-1145
  if (columnsByClassName === contentGridQuartersModifier.SINGLE_COLUMN) {
    switch (slidesPerView) {
      case 1:
        return '1.75em';
      case 2:
        return '1.25em';
      case 3:
        return '1em';
      default:
        throw Error(createIncorrectSlidePerViewErrorMessage(slidesPerView));
    }
  }

  if (columnsByClassName === contentGridQuartersModifier.TWO_COLUMNS) {
    switch (slidesPerView) {
      case 1:
        return '1.5em';
      case 2:
        return '1.1em';
      case 3:
        return '0.85em';
      default:
        throw Error(createIncorrectSlidePerViewErrorMessage(slidesPerView));
    }
  }

  if (columnsByClassName === contentGridQuartersModifier.THREE_COLUMNS) {
    switch (slidesPerView) {
      case 1:
        return '1.25em';
      case 2:
        return '0.85em';
      case 3:
        return '0.6em';
      default:
        throw Error(createIncorrectSlidePerViewErrorMessage(slidesPerView));
    }
  }

  return 'inherit';
};

function createIncorrectSlidePerViewErrorMessage(slidesPerView) {
  return `Incorrect slidesPerView value. Got ${slidesPerView} and the value should be in the range 1-3`;
}

import { useState } from 'react';
import { ClipboardContext } from './ClipboardContext';

const defaultProps = {
  children: null,
};

export const ClipboardProvider = ({ children } = defaultProps) => {
  const [clipboardInfo, setClipboardInfo] = useState(null);

  return (
    <ClipboardContext.Provider
      value={{
        clipboardInfo,
        setClipboardInfo,
      }}
    >
      {children}
    </ClipboardContext.Provider>
  );
};

export const productStatusKeys = {
  BESPOKE: 'bespoke',
  AVAILABLE: 'available',
  ANOTHER_STOREROOM: 'anotherStoreroom',
  TEMPORARILY_MISSING: 'temporarilyMissing',
  OVS: 'ovs',
};

export const productStatusMap = new Map([
  [0, productStatusKeys.BESPOKE],
  [1, productStatusKeys.AVAILABLE],
  [2, productStatusKeys.AVAILABLE],
  [3, productStatusKeys.ANOTHER_STOREROOM],
  [4, productStatusKeys.TEMPORARILY_MISSING],
  [5, productStatusKeys.OVS],
]);

import { useRouter } from 'next/router';
import { useEffect } from 'react';
import progressBar from 'src/utils/progressBar';

export const usePageLoadProgressBar = () => {
  const { events } = useRouter();

  useEffect(() => {
    events.on('routeChangeStart', () => {
      progressBar.start();
    });

    events.on('routeChangeComplete', () => {
      progressBar.end();
    });

    events.on('routeChangeError', () => {
      progressBar.end();
    });
    /* eslint-disable-next-line */
  }, []);
};

import { productStatusKeys } from 'src/enums/productStatus';
import { contactStatusKeys } from 'src/enums/contactStatus';
import { priceColorsKeys } from 'src/enums/priceColors';
import { orderStatusKeys } from 'src/enums/orderPaymentStatus';

import { offerStatusStyleKey } from 'src/enums/offerStatus';

import { breakpoints } from './breakpoints';
import { defaultPalette as p1 } from './palettes';
import { spacing } from './spacing';
import { withHelpers } from './helpers';
import * as animations from './animations';
import * as transitions from './transitions';
import * as typography from './typography';

export const defaultTheme = withHelpers({
  name: 'Default Theme',
  defaultPalette: {
    ...p1,
  },
  global: {
    bg: p1.monochrome[0],
    bgAlt: p1.prussianBlue[3],
    color: p1.monochrome[4],
    colorAlt: p1.teal[0],
    colorAlt2: p1.blazeOrange[0],
    borderColor: {
      primary: p1.monochrome[2],
    },
    fontFamily: {
      primary: typography.type.primary,
      secondary: typography.type.secondary,
      tertiary: typography.type.tertiary,
    },
    fontSizes: [typography.sizesSet.r1],
    maxWidth: '1260px',
  },
  heading: {
    primary: {
      color: p1.monochrome[4],
      fontFamily: typography.type.primary,
      fontSizes: [
        typography.sizesSet.r2,
        typography.sizesSet.r3,
        typography.sizesSet.r4,
        typography.sizesSet.r9,
        typography.sizesSet.r16,
        typography.sizesSet.r1,
        typography.sizesSet.r19,
        typography.sizesSet.r10,
        typography.sizesSet.r7,
        typography.sizesSet.r13,
      ],
    },
    secondary: {
      color: p1.monochrome[4],
      fontFamily: typography.type.tertiary,
      fontSizes: [
        typography.sizesSet.r3,
        typography.sizesSet.r4,
        typography.sizesSet.r10,
        typography.sizesSet.r9,
        typography.sizesSet.r11,
        typography.sizesSet.r2,
        typography.sizesSet.r17,
        typography.sizesSet.r1,
        typography.sizesSet.r19,
      ],
      small: {
        fontFamily: typography.type.primary,
      },
    },
    tertiary: {
      color: p1.teal[0],
      fontFamily: typography.type.primary,

      fontSizes: [
        typography.sizesSet.r3,
        typography.sizesSet.r4,
        typography.sizesSet.r17,
        typography.sizesSet.r22,
        typography.sizesSet.r23,
      ],
    },
    quaternary: {
      color: p1.monochrome[4],
      fontFamily: typography.type.primary,
      fontSizes: [typography.sizesSet.r3, typography.sizesSet.r4, typography.sizesSet.r10, typography.sizesSet.r17],
      decorFg: p1.monochrome[0],
      decorBg: p1.monochrome[2],
    },
    quinary: {
      color: p1.monochrome[4],
      fontFamily: typography.type.tertiary,
      fontSizes: [typography.sizesSet.r1],
      decogBg: p1.teal[0],
    },
    senary: {
      color: p1.prussianBlue[0],
      fontFamily: typography.type.tertiary,
      fontSizes: [typography.sizesSet.r2, typography.sizesSet.r3, typography.sizesSet.r4, typography.sizesSet.r9],
    },
    septenary: {
      color: p1.monochrome[4],
      fontFamily: typography.type.primary,
      fontSizes: [typography.sizesSet.r2, typography.sizesSet.r3, typography.sizesSet.r4, typography.sizesSet.r9],
      smallScreenColors: {
        background: p1.monochrome[1],
        text: p1.monochrome[4],
      },
    },
    octonary: {
      color: p1.teal[0],
      fontFamily: typography.type.primary,
      fontSizes: [typography.sizesSet.r3, typography.sizesSet.r4, typography.sizesSet.r10, typography.sizesSet.r1],
      decorFg: p1.monochrome[0],
      decorBg: p1.monochrome[2],
    },
    nonary: {
      color: p1.monochrome[4],
      fontFamily: typography.type.secondary,
      fontSizes: [
        typography.sizesSet.r3,
        typography.sizesSet.r4,
        typography.sizesSet.r10,
        typography.sizesSet.r9,
        typography.sizesSet.r11,
        typography.sizesSet.r2,
        typography.sizesSet.r17,
        typography.sizesSet.r19,
      ],
      small: {
        fontFamily: typography.type.primary,
      },
    },
    denary: {
      color: p1.prussianBlue[0],
      fontFamily: typography.type.secondary,
      fontSizes: [typography.sizesSet.r2, typography.sizesSet.r3, typography.sizesSet.r4, typography.sizesSet.r9],
    },
    duodenary: {
      color: p1.monochrome[3],
      bg: p1.monochrome[0],
      fontFamily: typography.type.primary,
      fontSizes: [typography.sizesSet.r7],
      lineBg: p1.monochrome[2],
    },
    underlineVariant: {
      bottomMargin: '1em',
    },
  },
  text: {
    primary: {
      color: p1.monochrome[4],
      fontFamily: typography.type.primary,
      fontSizes: [
        typography.sizesSet.r1,
        typography.sizesSet.r8,
        typography.sizesSet.r12,
        typography.sizesSet.r3,
        typography.sizesSet.r11,
        typography.sizesSet.r9,
        typography.sizesSet.r10,
        typography.sizesSet.r15,
        typography.sizesSet.r16,
        typography.sizesSet.r18,
        typography.sizesSet.r19,
        typography.sizesSet.r7,
      ],
    },
    secondary: {
      color: p1.monochrome[3],
      fontFamily: typography.type.primary,
      fontSizes: [
        typography.sizesSet.r8,
        typography.sizesSet.r1,
        typography.sizesSet.r10,
        typography.sizesSet.r15,
        typography.sizesSet.r7,
        typography.sizesSet.r1,
      ],
    },
    tertiary: {
      color: p1.blazeOrange[0],
      fontFamily: typography.type.tertiary,
      fontSizes: [
        typography.sizesSet.r1,
        typography.sizesSet.r12,
        typography.sizesSet.r7,
        typography.sizesSet.r3,
        typography.sizesSet.r8,
      ],
    },
    quaternary: {
      color: p1.monochrome[4],
      fontFamily: typography.type.tertiary,
      fontSizes: [
        typography.sizesSet.r1,
        typography.sizesSet.r12,
        typography.sizesSet.r9,
        typography.sizesSet.r10,
        typography.sizesSet.r15,
      ],
    },
    quinary: {
      color: p1.monochrome[4],
      fontFamily: typography.type.primary,
      fontSizes: [
        typography.sizesSet.r1,
        typography.sizesSet.r8,
        typography.sizesSet.r12,
        typography.sizesSet.r9,
        typography.sizesSet.r8,
      ],
    },
    senary: {
      color: p1.teal[0],
      fontFamily: typography.type.tertiary,
      fontSizes: [typography.sizesSet.r9, typography.sizesSet.r7],
    },
    septenary: {
      color: p1.alizarinCrimson[1],
      fontFamily: typography.type.primary,
      fontSizes: [
        typography.sizesSet.r8,
        typography.sizesSet.r1,
        typography.sizesSet.r10,
        typography.sizesSet.r15,
        typography.sizesSet.r7,
      ],
    },
    octonary: {
      color: p1.monochrome[5],
      fontFamily: typography.type.tertiary,
      fontSizes: [typography.sizesSet.r13, typography.sizesSet.r16, typography.sizesSet.r15, typography.sizesSet.r1],
    },
    nonary: {
      color: p1.teal[0],
      fontFamily: typography.type.tertiary,
      fontSizes: [
        typography.sizesSet.r9,
        typography.sizesSet.r15,
        typography.sizesSet.r19,
        typography.sizesSet.r1,
        typography.sizesSet.r7,
        typography.sizesSet.r1,
      ],
    },
    denary: {
      color: p1.alizarinCrimson[1],
      fontFamily: typography.type.tertiary,
      fontSizes: [
        typography.sizesSet.r8,
        typography.sizesSet.r1,
        typography.sizesSet.r10,
        typography.sizesSet.r15,
        typography.sizesSet.r7,
      ],
    },
    duodenary: {
      color: p1.chateauGreen[0],
      fontFamily: typography.type.tertiary,
      fontSizes: [
        typography.sizesSet.r1,
        typography.sizesSet.r8,
        typography.sizesSet.r12,
        typography.sizesSet.r9,
        typography.sizesSet.r8,
      ],
    },
    tridenary: {
      color: p1.teal[0],
      fontFamily: typography.type.primary,
      fontSizes: [
        typography.sizesSet.r1,
        typography.sizesSet.r8,
        typography.sizesSet.r12,
        typography.sizesSet.r9,
        typography.sizesSet.r8,
      ],
    },
    quattuordenary: {
      color: p1.monochrome[7],
      fontFamily: typography.type.primary,
      fontSizes: [
        typography.sizesSet.r1,
        typography.sizesSet.r8,
        typography.sizesSet.r12,
        typography.sizesSet.r9,
        typography.sizesSet.r8,
      ],
    },

    quinquadenary: {
      color: p1.alizarinCrimson[1],
      fontFamily: typography.type.primary,
      fontSizes: [
        typography.sizesSet.r1,
        typography.sizesSet.r8,
        typography.sizesSet.r12,
        typography.sizesSet.r9,
        typography.sizesSet.r8,
      ],
    },
  },

  bulletList: {
    primary: {
      item: {
        color: p1.monochrome[4],
      },
      header: {
        fontFamily: typography.type.tertiary,
      },
      bullet: {
        color: p1.teal[0],
        unicode: '•',
      },
    },
    secondary: {
      item: {
        color: p1.monochrome[4],
      },
      header: {
        fontFamily: typography.type.tertiary,
      },
      bullet: {
        color: p1.blazeOrange[0],
        unicode: '◦',
      },
    },
    tertiary: {
      item: {
        color: p1.monochrome[3],
      },
      header: {
        fontFamily: typography.type.tertiary,
      },
      bullet: {
        color: p1.blazeOrange[0],
        unicode: '•',
      },
    },
    quaternary: {
      item: {
        color: p1.monochrome[4],
      },
      header: {
        fontFamily: typography.type.tertiary,
      },
    },
  },
  link: {
    primary: {
      fontFamily: typography.type.primary,
      color: {
        default: p1.monochrome[4],
        hover: p1.blazeOrange[0],
        active: p1.alizarinCrimson[0],
      },
      underLineColor: p1.blazeOrange[0],
    },
    secondary: {
      fontFamily: typography.type.primary,
      color: {
        default: p1.monochrome[0],
        hover: p1.prussianBlue[2],
        active: p1.prussianBlue[1],
      },
    },
    tertiary: {
      fontFamily: typography.type.primary,
      color: {
        default: p1.teal[0],
        hover: p1.teal[1],
        active: p1.teal[2],
      },
    },
    quaternary: {
      fontFamily: typography.type.primary,
      color: {
        default: p1.monochrome[4],
        hover: p1.blazeOrange[0],
        active: p1.alizarinCrimson[0],
      },
      iconColor: p1.blazeOrange[0],
    },
    quinary: {
      fontFamily: typography.type.tertiary,
      color: {
        default: p1.prussianBlue[0],
        hover: p1.prussianBlue[0],
        active: p1.prussianBlue[0],
      },
      underLineColor: p1.blazeOrange[0],
      iconColor: p1.blazeOrange[0],
    },
    senary: {
      fontFamily: typography.type.tertiary,
      color: {
        default: p1.monochrome[4],
        hover: p1.monochrome[4],
        active: p1.monochrome[4],
      },
      underLineColor: p1.blazeOrange[0],
      iconColor: p1.blazeOrange[0],
    },
    septenary: {
      fontFamily: typography.type.primary,
      color: {
        default: p1.monochrome[4],
        hover: p1.teal[0],
        active: p1.teal[1],
      },
      iconColor: p1.teal[0],
    },
    octonary: {
      fontFamily: typography.type.primary,
      color: {
        default: p1.monochrome[4],
        hover: p1.teal[0],
        active: p1.teal[1],
      },
      iconColor: p1.teal[0],
      underLineColor: p1.teal[0],
    },
    nonary: {
      fontFamily: typography.type.primary,
      color: {
        default: p1.teal[0],
        hover: p1.teal[1],
        active: p1.teal[1],
      },
      basedColor: p1.monochrome[4],
      iconColor: p1.teal[0],
    },
    denary: {
      fontFamily: typography.type.tertiary,
      color: {
        default: p1.monochrome[4],
        hover: p1.blazeOrange[0],
        active: p1.alizarinCrimson[0],
      },
    },
    duodenary: {
      fontFamily: typography.type.primary,
      color: {
        default: p1.teal[0],
        hover: p1.teal[1],
        active: p1.teal[1],
      },
      basedColor: p1.monochrome[4],
      iconColor: p1.teal[0],
    },
    tridenary: {
      fontFamily: typography.type.primary,
      color: {
        default: p1.monochrome[4],
        hover: p1.teal[0],
        active: p1.teal[1],
      },
      iconColor: p1.teal[0],
      underLineColor: p1.teal[0],
    },
    quattuordenary: {
      fontFamily: typography.type.secondary,
      color: {
        default: p1.prussianBlue[3],
        hover: p1.prussianBlue[3],
        active: p1.prussianBlue[3],
      },
    },
    quindenary: {
      fontFamily: typography.type.primary,
      color: {
        default: p1.blazeOrange[0],
        hover: p1.blazeOrange[1],
        active: p1.blazeOrange[1],
      },
      basedColor: p1.monochrome[4],
      iconColor: p1.blazeOrange[0],
    },
    sedenary: {
      fontFamily: typography.type.primary,
      color: {
        default: p1.teal[0],
        hover: p1.teal[1],
        active: p1.teal[1],
      },
      underLineColor: p1.teal[0],
    },
    septendenary: {
      fontFamily: typography.type.tertiary,
      color: {
        default: p1.teal[0],
        hover: p1.teal[1],
        active: p1.teal[1],
      },
      basedColor: p1.monochrome[4],
      iconColor: p1.teal[0],
    },
    octodenary: {
      fontFamily: typography.type.primary,
      color: {
        default: p1.monochrome[3],
        hover: p1.monochrome[4],
        active: p1.monochrome[4],
      },
      basedColor: p1.monochrome[4],
      iconColor: p1.teal[0],
    },
    novendenary: {
      fontFamily: typography.type.primary,
      color: {
        default: p1.monochrome[4],
        hover: p1.teal[0],
        active: p1.teal[1],
      },
      iconColor: p1.teal[0],
    },
  },
  button: {
    allVariants: {
      opacity: {
        disabled: 0.75,
      },
    },
    primary: {
      fontSizes: [
        typography.sizesSet.r1,
        typography.sizesSet.r7,
        typography.sizesSet.r4,
        typography.sizesSet.r10,
        typography.sizesSet.r9,
      ],
      fontFamily: typography.type.primary,
      color: {
        default: p1.monochrome[0],
        hover: p1.monochrome[0],
        active: p1.monochrome[0],
      },
      bg: {
        default: p1.teal[0],
        hover: p1.teal[1],
        active: p1.teal[2],
      },
    },
    secondary: {
      fontSizes: [typography.sizesSet.r1, typography.sizesSet.r7, typography.sizesSet.r4],
      fontFamily: typography.type.primary,
      color: {
        default: p1.monochrome[0],
        hover: p1.monochrome[0],
        active: p1.monochrome[0],
      },
      bg: {
        default: p1.blazeOrange[0],
        hover: p1.alizarinCrimson[0],
        active: p1.alizarinCrimson[1],
      },
    },
    tertiary: {
      fontSizes: [typography.sizesSet.r1, typography.sizesSet.r7, typography.sizesSet.r4],
      fontFamily: typography.type.primary,
      color: {
        default: p1.teal[0],
        hover: p1.teal[1],
        active: p1.teal[2],
      },
      bg: {
        default: p1.monochrome[0],
        hover: p1.monochrome[0],
        active: p1.monochrome[0],
      },
    },
    quaternary: {
      fontSizes: [typography.sizesSet.r1, typography.sizesSet.r7, typography.sizesSet.r4],
      fontFamily: typography.type.primary,
      color: {
        default: p1.monochrome[0],
        hover: p1.monochrome[0],
        active: p1.monochrome[0],
      },
      bg: {
        default: p1.blazeOrange[0],
        hover: p1.blazeOrange[3],
        active: p1.blazeOrange[3],
      },
    },
    quinary: {
      fontSizes: [typography.sizesSet.r1, typography.sizesSet.r7, typography.sizesSet.r4],
      fontFamily: typography.type.primary,
      color: {
        default: p1.monochrome[0],
        hover: p1.monochrome[0],
        active: p1.monochrome[0],
      },
      bg: {
        default: p1.monochrome[3],
        hover: p1.monochrome[3],
        active: p1.monochrome[3],
      },
    },
    senary: {
      fontSizes: [typography.sizesSet.r8, typography.sizesSet.r7, typography.sizesSet.r4],
      fontFamily: typography.type.primary,
      color: {
        default: p1.monochrome[4],
        hover: p1.monochrome[2],
        active: p1.monochrome[0],
      },
      bg: {
        default: p1.monochrome[0],
        hover: p1.monochrome[2],
        active: p1.monochrome[4],
      },
      border: {
        default: p1.monochrome[2],
        hover: p1.monochrome[2],
        active: p1.monochrome[4],
      },
    },
    septenary: {
      fontSizes: [typography.sizesSet.r1, typography.sizesSet.r7, typography.sizesSet.r4],
      fontFamily: typography.type.primary,
      color: {
        default: p1.chateauGreen[0],
        hover: p1.monochrome[0],
        active: p1.monochrome[0],
      },
      bg: {
        default: p1.monochrome[2],
        hover: p1.monochrome[2],
        active: p1.chateauGreen[0],
      },
    },
    octonary: {
      fontSizes: [typography.sizesSet.r1, typography.sizesSet.r7, typography.sizesSet.r4],
      fontFamily: typography.type.primary,
      color: {
        default: p1.alizarinCrimson[0],
        hover: p1.alizarinCrimson[0],
        active: p1.alizarinCrimson[0],
      },
      bg: {
        default: p1.monochrome[0],
        hover: p1.monochrome[2],
        active: p1.alizarinCrimson[0],
      },
    },
    nonary: {
      fontSizes: [typography.sizesSet.r6],
      fontFamily: typography.type.primary,
      color: {
        default: p1.monochrome[4],
        hover: p1.monochrome[0],
        active: p1.monochrome[0],
      },
      bg: {
        default: p1.monochrome[0],
        hover: p1.teal[0],
        active: p1.monochrome[0],
      },
    },
  },
  table: {
    fontSizes: [typography.sizesSet.r6, typography.sizesSet.r7, typography.sizesSet.r4, typography.sizesSet.r9],
    fontFamily: typography.type.tertiary,
    color: {
      background: p1.monochrome[1],
      paragraphLabel: p1.monochrome[3],
      heading: p1.teal[0],
      tableBorder: p1.teal[0],
      value: p1.monochrome[0],
      arrow: p1.blazeOrange[0],
      greenText: p1.chateauGreen[0],
      grayText: p1.monochrome[3],
      expandBorder: p1.monochrome[3],
    },
  },

  input: {
    tooltipColor: p1.monochrome[3],
    tooltipFontSize: typography.size.s1,
    primary: {
      fontFamily: {
        label: typography.type.tertiary,
        input: typography.type.primary,
        error: typography.type.primary,
      },
      fontSizes: {
        label: typography.sizesSet.r8,
        input: typography.sizesSet.r1,
        error: typography.sizesSet.r8,
      },
      color: {
        label: p1.monochrome[3],
        input: p1.monochrome[4],
        inputFocused: p1.monochrome[4],
        error: p1.alizarinCrimson[0],
        placeholder: p1.monochrome[3],
        icon: p1.teal[0],
        clear: p1.monochrome[0],
        requiredSign: p1.alizarinCrimson[0],
      },
      bg: {
        label: 'none',
        input: p1.monochrome[0],
        inputFocused: p1.paleYellow[0],
        error: 'none',
        icon: p1.monochrome[0],
        clear: p1.teal[1],
        readOnly: p1.monochrome[1],
      },
      borderColor: {
        input: {
          default: p1.monochrome[2],
          focused: p1.teal[0],
          invalid: p1.alizarinCrimson[0],
          confirmed: p1.teal[0],
        },
      },
    },
    secondary: {
      fontFamily: {
        label: typography.type.tertiary,
        input: typography.type.primary,
        error: typography.type.primary,
      },
      fontSizes: {
        label: typography.sizesSet.r8,
        input: typography.sizesSet.r1,
        error: typography.sizesSet.r8,
      },
      color: {
        label: p1.monochrome[4],
        input: p1.monochrome[4],
        inputFocused: p1.monochrome[4],
        error: p1.alizarinCrimson[0],
        placeholder: p1.monochrome[3],
        icon: p1.teal[0],
        clear: p1.monochrome[0],
        requiredSign: p1.alizarinCrimson[0],
      },
      bg: {
        label: 'none',
        input: p1.monochrome[0],
        inputFocused: p1.paleYellow[0],
        error: 'none',
        icon: p1.monochrome[0],
        clear: p1.teal[1],
      },
      borderColor: {
        input: {
          default: p1.monochrome[2],
          focused: p1.monochrome[2],
          invalid: p1.alizarinCrimson[0],
          confirmed: p1.monochrome[2],
        },
      },
    },
    tertiary: {
      fontFamily: {
        label: typography.type.tertiary,
        input: typography.type.primary,
        error: typography.type.primary,
      },
      fontSizes: {
        label: typography.sizesSet.r8,
        input: typography.sizesSet.r1,
        error: typography.sizesSet.r8,
      },
      color: {
        label: p1.monochrome[3],
        input: p1.monochrome[4],
        inputFocused: p1.monochrome[4],
        error: p1.alizarinCrimson[0],
        placeholder: p1.monochrome[3],
        icon: p1.prussianBlue[0],
        clear: p1.monochrome[0],
        requiredSign: p1.alizarinCrimson[0],
      },
      bg: {
        label: 'none',
        input: p1.monochrome[1],
        inputDesktop: p1.monochrome[0],
        inputFocused: p1.paleYellow[0],
        inputFocusedDesktop: p1.paleYellow[0],
        error: 'none',
        icon: 'none',
        clear: p1.teal[1],
      },
      borderColor: {
        input: {
          default: p1.teal[0],
          focused: p1.teal[1],
          invalid: p1.alizarinCrimson[0],
          confirmed: p1.blazeOrange[0],
        },
      },
    },
    quaternary: {
      fontFamily: {
        label: typography.type.tertiary,
        input: typography.type.primary,
        error: typography.type.primary,
      },
      fontSizes: {
        label: typography.sizesSet.r8,
        input: typography.sizesSet.r1,
        error: typography.sizesSet.r8,
      },
      color: {
        label: p1.monochrome[3],
        input: p1.monochrome[4],
        inputFocused: p1.monochrome[4],
        error: p1.alizarinCrimson[0],
        placeholder: p1.monochrome[3],
        icon: p1.teal[0],
        clear: p1.monochrome[0],
        requiredSign: p1.alizarinCrimson[0],
      },
      bg: {
        label: 'none',
        input: p1.monochrome[0],
        inputFocused: p1.paleYellow[0],
        error: 'none',
        icon: p1.monochrome[0],
        clear: p1.teal[1],
        readOnly: p1.monochrome[1],
      },
      borderColor: {
        input: {
          default: p1.monochrome[2],
          focused: p1.teal[0],
          invalid: p1.alizarinCrimson[0],
          confirmed: p1.teal[0],
        },
      },
    },
    quinary: {
      fontFamily: {
        label: typography.type.tertiary,
        input: typography.type.primary,
        error: typography.type.primary,
      },
      fontSizes: {
        label: typography.sizesSet.r8,
        input: typography.sizesSet.r1,
        error: typography.sizesSet.r8,
      },
      color: {
        label: p1.monochrome[3],
        input: p1.monochrome[4],
        inputFocused: p1.monochrome[4],
        error: p1.alizarinCrimson[0],
        placeholder: p1.monochrome[3],
        icon: p1.teal[0],
        clear: p1.monochrome[0],
        requiredSign: p1.alizarinCrimson[0],
      },
      bg: {
        label: 'none',
        input: p1.monochrome[0],
        inputFocused: p1.paleYellow[0],
        error: 'none',
        icon: p1.monochrome[0],
        clear: p1.teal[1],
      },
      borderColor: {
        input: {
          default: p1.teal[0],
          focused: p1.teal[1],
          invalid: p1.alizarinCrimson[0],
          confirmed: p1.blazeOrange[0],
        },
      },
    },
    senary: {
      fontFamily: {
        label: typography.type.tertiary,
        input: typography.type.primary,
        error: typography.type.primary,
      },
      fontSizes: {
        label: typography.sizesSet.r8,
        input: typography.sizesSet.r1,
        error: typography.sizesSet.r8,
      },
      color: {
        label: p1.teal[0],
        input: p1.teal[0],
        inputFocused: p1.teal[0],
        error: p1.alizarinCrimson[0],
        placeholder: p1.monochrome[3],
        icon: p1.teal[0],
        clear: p1.monochrome[0],
        requiredSign: p1.blazeOrange[0],
      },
      bg: {
        label: 'none',
        input: p1.monochrome[0],
        inputFocused: p1.paleYellow[0],
        error: 'none',
        icon: p1.monochrome[0],
        clear: p1.teal[1],
      },
      borderColor: {
        input: {
          default: p1.monochrome[2],
          focused: p1.teal[0],
          invalid: p1.alizarinCrimson[0],
          confirmed: p1.teal[0],
        },
      },
    },
  },
  textarea: {
    primary: {
      fontFamily: {
        label: typography.type.tertiary,
        textarea: typography.type.primary,
        error: typography.type.primary,
      },
      fontSizes: {
        label: typography.sizesSet.r8,
        textarea: typography.sizesSet.r1,
        error: typography.sizesSet.r8,
      },
      color: {
        label: p1.monochrome[3],
        textarea: p1.monochrome[4],
        textareaFocused: p1.monochrome[4],
        error: p1.alizarinCrimson[0],
        placeholder: p1.monochrome[3],
        icon: p1.teal[0],
        clear: p1.monochrome[0],
        requiredSign: p1.alizarinCrimson[0],
      },
      bg: {
        label: 'none',
        textarea: p1.monochrome[0],
        textareaFocused: p1.paleYellow[0],
        error: 'none',
        icon: p1.monochrome[0],
        clear: p1.teal[1],
      },
      borderColor: {
        textarea: {
          default: p1.monochrome[2],
          focused: p1.teal[0],
          invalid: p1.alizarinCrimson[0],
          confirmed: p1.teal[0],
        },
      },
    },
  },

  checkbox: {
    primary: {
      ratio: {
        margin: 0.5,
        scale: 1.5,
        topDistance: 2,
        left: -4,
      },
      bgSrc: {
        default: '/static/svg/checkbox-unchecked-v1.svg',
        highlighted: '/static/svg/checkbox-highlighted-v1.svg',
        checked: '/static/svg/checkbox-checked-v1.svg',
        disabled: '/static/svg/checkbox-disabled-unchecked-v1.svg',
        disabledChecked: '/static/svg/checkbox-disabled-checked-v1.svg',
        disabledCheckedInvisible: '/static/svg/checkbox-checked-v1.svg',
      },
      fontFamily: {
        label: typography.type.primary,
      },
      color: {
        label: p1.monochrome[4],
        error: p1.alizarinCrimson[0],
        requiredSign: p1.alizarinCrimson[0],
        border: p1.monochrome[3],
      },
    },
    secondary: {
      ratio: {
        margin: 0.3,
        scale: 1,
        topDistance: 1,
        left: 0,
      },
      bgSrc: {
        default: '/static/svg/radio-unchecked-v1.svg',
        highlighted: '/static/svg/checkbox-highlighted-v1.svg',
        checked: '/static/svg/radio-checked-v1.svg',
        disabled: '/static/svg/radio-disabled-unchecked-v1.svg',
        disabledChecked: '/static/svg/radio-disabled-checked-v1.svg',
      },
      fontFamily: {
        label: typography.type.primary,
      },
      color: {
        label: p1.monochrome[4],
        error: p1.alizarinCrimson[0],
        requiredSign: p1.alizarinCrimson[0],
        border: p1.teal[0],
      },
    },
  },
  price: {
    fontSizes: [
      typography.sizesSet.r9,
      typography.sizesSet.r4,
      typography.sizesSet.r13,
      typography.sizesSet.r10,
      typography.sizesSet.r19,
    ],
    fontFamily: typography.type.primary,
    fontFamilyPriceValue: typography.type.secondary,
    color: {
      [priceColorsKeys.NORMAL]: p1.monochrome[4],
      [priceColorsKeys.PROMOTIONS]: p1.alizarinCrimson[1],
      [priceColorsKeys.AWARDED]: p1.blazeOrange[0],
      [priceColorsKeys.SALE]: p1.prussianBlue[0],
      [priceColorsKeys.EXTRA_DISCOUNT]: p1.chateauGreen[0],
    },
  },
  orderStatus: {
    fontFamily: typography.type.tertiary,
    color: {
      [orderStatusKeys.UNREGISTERED]: p1.monochrome[4],
      [orderStatusKeys.UNPAID]: p1.alizarinCrimson[0],
      [orderStatusKeys.PAID]: p1.chateauGreen[0],
    },
  },
  badge: {
    allVariants: {
      fontSizes: [typography.sizesSet.r6],
      fontFamily: typography.type.primary,
    },
    [contactStatusKeys.UNKNOWN]: {
      color: p1.monochrome[0],
      bg: p1.blazeOrange[0],
    },

    [contactStatusKeys.AVAILABLE]: {
      color: p1.monochrome[0],
      bg: p1.chateauGreen[0],
    },
    [contactStatusKeys.HOLIDAY]: {
      color: p1.monochrome[0],
      bg: p1.alizarinCrimson[1],
    },
    [contactStatusKeys.DISEASE]: {
      color: p1.monochrome[0],
      bg: p1.alizarinCrimson[1],
    },
    [contactStatusKeys.NOT_WORK]: {
      color: p1.monochrome[0],
      bg: p1.alizarinCrimson[1],
    },
  },

  form: {
    maxWidth: {
      extraSmall: 570,
      small: 640,
      large: 1280,
    },
    icon: {
      color: p1.blazeOrange[0],
    },
    note: {
      color: p1.monochrome[3],
    },
  },

  pagination: {
    item: {
      color: {
        default: p1.monochrome[4],
        hover: p1.teal[1],
        active: p1.teal[2],
      },
    },
    arrow: {
      color: {
        default: p1.blazeOrange[0],
        hover: p1.alizarinCrimson[0],
        active: p1.alizarinCrimson[1],
        disabled: p1.monochrome[1],
        rounded: p1.monochrome[3],
      },
    },
  },
  header: {
    highlight: {
      color: p1.teal[0],
      fontFamily: typography.type.tertiary,
    },
    fontSizeSet: {
      extraSmall: typography.sizesSet.r6,
      small: typography.sizesSet.r7,
      large: typography.sizesSet.r9,
    },
    bottomBar: {
      bg: p1.monochrome[1],
      menuBg: p1.prussianBlue[0],
      fontFamily: typography.type.secondary,
    },
    lightMenu: {
      bgAnchor: p1.monochrome[2],
      fontColor: p1.prussianBlue[3],
    },
    iconColor: p1.monochrome[0],
    burgerColor: p1.teal[0],
    accountIconColor: p1.teal[0],
    logInIconColor: p1.blazeOrange[0],
    search: {
      fontFamily: typography.type.secondary,
      searchManyColor: p1.teal[0],
    },
    select: {
      buttonColor: p1.monochrome[0],
      buttonBackground: p1.prussianBlue[3],
      listBackground: p1.monochrome[0],
      listColor: p1.monochrome[5],
      boxShadow: p1.monochrome[5],
      backgroundHighlight: p1.prussianBlue[4],
      fontFamily: {
        default: typography.type.primary,
        selected: typography.type.secondary,
      },
      footerBg: p1.monochrome[1],
      footerIconFill: p1.teal[0],
      footerLabelHover: typography.type.secondary,
    },
    userAccountLink: {
      color: p1.teal[1],
    },
  },

  breadcrumbs: {
    icon: {
      color: p1.teal[0],
    },
  },

  productPricesList: {
    color: {
      selectionIcon: p1.blazeOrange[0],
      label: p1.monochrome[3],
      value: p1.monochrome[4],
    },
    fontSize: {
      icon: typography.sizesSet.r8,
      crossedPrice: typography.sizesSet.r1,
    },
    afterPricePiece: typography.sizesSet.r1,
    saleBadge: {
      bg: p1.prussianBlue[0],
      color: p1.monochrome[0],
      fontSize: typography.sizesSet.r6,
    },
    colors: {
      [priceColorsKeys.NORMAL]: p1.monochrome[4],
      [priceColorsKeys.PROMOTIONS]: p1.alizarinCrimson[1],
      [priceColorsKeys.AWARDED]: p1.blazeOrange[0],
      [priceColorsKeys.SALE]: p1.prussianBlue[0],
      [priceColorsKeys.EXTRA_DISCOUNT]: p1.chateauGreen[0],
      default: p1.teal[0],
    },
    primary: {
      fontFamily: typography.type.tertiary,
      fontSizes: [typography.sizesSet.r10],
    },
    secondary: {
      fontFamily: typography.type.secondary,
      fontSizes: [typography.sizesSet.r19],
    },
    tertiary: {
      fontFamily: typography.type.primary,
      fontSizes: [typography.sizesSet.r15],
    },
  },

  basket: {
    colors: {
      primaryHeader: p1.monochrome[4],
      secondaryHeader: p1.teal[0],
    },
    fontFamily: {
      primaryHeader: typography.type.primary,
      secondaryHeader: typography.type.tertiary,
    },
    headerCheckbox: {
      primary: {
        topDistance: 8,
      },
      secondary: {
        topDistance: 2,
      },
    },
    borderColor: p1.monochrome[1],
    manufacturerColor: p1.monochrome[3],
  },

  basketProductsList: {
    colors: {
      deleteIcon: p1.blazeOrange[0],
      indexNumber: p1.monochrome[3],
      label: p1.monochrome[3],
      warehouse: p1.teal[0],
      creditLimit: p1.blazeOrange[0],
      hrColor: p1.monochrome[2],
    },
    fontSizes: {
      heading: typography.sizesSet.r3,
      downloadLink: typography.sizesSet.r7,
      price: typography.sizesSet.r1,
    },
    bg: {
      info: p1.sandYellow[1],
      alert: p1.pastelPink[0],
      iconBg: p1.monochrome[1],
    },
  },

  productAdditionalInfo: {
    color: {
      label: p1.monochrome[4],
      labelSelected: p1.blazeOrange[0],
      icon: p1.blazeOrange[0],
      dimensions: p1.monochrome[3],
      heading: p1.teal[0],
      background: p1.monochrome[1],
      discountLabel: p1.monochrome[3],
    },
    fontSize: {
      general: typography.sizesSet.r7,
    },
  },
  productAvailability: {
    conditionStyle: {
      [productStatusKeys.AVAILABLE]: {
        bgColor: p1.chateauGreen[0],
        borderColor: p1.chateauGreen[0],
      },
      [productStatusKeys.TEMPORARILY_MISSING]: {
        borderColor: p1.chateauGreen[0],
      },
    },
    iconColor: p1.teal[0],
    localVolumeColor: p1.blazeOrange[0],
  },
  priceSection: {
    labelColor: p1.monochrome[3],
    indicatorIconColor: p1.blazeOrange[0],
    indicatorIconSize: typography.sizesSet.r6,
    labelFontSize: typography.sizesSet.r7,
  },

  productCardSummary: {
    color: {
      icon: p1.blazeOrange[0],
      backgroundDesktop: p1.monochrome[1],
      label: p1.monochrome[3],
      checkWarehouse: p1.blazeOrange[0],
      availabilityAmount: p1.teal[0],
      thumbBorder: p1.monochrome[0],
      inputButton: p1.monochrome[0],
      mobileInputButton: p1.monochrome[1],
      addToClipboardButton: p1.teal[0],
      addToClipboardButtonHover: p1.teal[1],
      lastPricesInfo: p1.monochrome[3],
    },
    fontSize: {
      costTextSecondLine: typography.size.s2,
      lastPricesInfo: typography.size.s1,
      lastPriceInfoIcon: typography.size.s2,
    },
    fontFamily: {
      title: typography.type.tertiary,
    },
  },

  addToBasketInput: {
    controls: {
      bg: {
        default: p1.monochrome[1],
        inverse: p1.monochrome[0],
        active: p1.teal[1],
      },
      color: {
        default: p1.teal[0],
        inverse: p1.teal[0],
        active: p1.monochrome[0],
      },
    },
  },

  orderHeader: {
    linkDotColor: p1.blazeOrange[0],
    color: p1.monochrome[3],
  },

  order: {
    accordionHeader: {
      color: p1.teal[0],
      backgroundColor: p1.monochrome[1],
      iconColor: p1.blazeOrange[0],
    },
  },
  productPhotos: {
    borderColor: p1.monochrome[1],
    activeBorderColor: p1.teal[0],
    colors: {
      background: p1.monochrome[1],
    },
    verticalLine: {
      color: p1.monochrome[2],
    },
    loadingMessage: {
      color: p1.monochrome[3],
      fontSize: typography.sizesSet.r10,
    },
  },

  reactDataTable: {
    th: {
      bg: p1.monochrome[1],
      fontFamily: typography.type.tertiary,
      roundedFontFamily: typography.type.primary,
      color: p1.teal[0],
      roundedColor: p1.monochrome[4],
      borderColor: p1.monochrome[3],
      sortArrowColor: p1.blazeOrange[0],
    },
    tbody: {
      fontSize: typography.size.s2,
    },
    tableRow: {
      evenBg: p1.monochrome[6],
      oddBg: p1.monochrome[0],
    },
    tableCell: {
      expandIconColor: p1.blazeOrange[0],
    },
    expandedCell: {
      borderColor: p1.monochrome[3],
    },
    expandedTable: {
      th: {
        bg: p1.monochrome[0],
        borderColor: p1.monochrome[3],
      },
    },
    scrollbar: {
      trackColor: p1.teal[0],
      thumbColor: p1.monochrome[6],
    },
    tabs: {
      activeBg: p1.monochrome[1],
      activeColor: p1.teal[0],
      color: p1.monochrome[3],
      fontFamily: typography.type.secondary,
      borderColor: p1.monochrome[3],
      bg: p1.monochrome[0],
    },
  },

  invoicesTableFooterDetails: {
    label: {
      fontFamily: typography.type.secondary,
      color: p1.monochrome[3],
    },
    value: {
      fontFamily: typography.type.tertiary,
      color: p1.monochrome[4],
    },
  },

  articleExcerpt: {
    variants: [
      {
        marginBetweenSiblings: '2em',
        unpublishingDate: {
          color: p1.teal[0],
          fontSize: [typography.sizesSet.r12, typography.sizesSet.r8, typography.sizesSet.r8],
        },
      },
      {
        marginBetweenSiblings: '1em',
        unpublishingDate: {
          color: p1.teal[0],
          fontSize: [typography.sizesSet.r8, typography.sizesSet.r12, typography.sizesSet.r11],
        },
      },
    ],
    sectionBorderColor: p1.monochrome[2],
  },

  accessContent: {
    link: {
      fontSizes: [typography.sizesSet.r1, typography.sizesSet.r7, typography.sizesSet.r4],
      fontFamily: typography.type.primary,
      color: p1.monochrome[0],
      bg: {
        default: p1.teal[0],
        hover: p1.teal[1],
        active: p1.teal[2],
      },
    },
  },

  offerStatusVariantText: {
    color: {
      [offerStatusStyleKey.DEFAULT]: p1.monochrome[5],
      [offerStatusStyleKey.SUCCESS]: p1.chateauGreen[0],
      [offerStatusStyleKey.DANGER]: p1.blazeOrange[0],
    },
  },

  userDropDownMenu: {
    bg: p1.monochrome[0],
    dropdownContentColor: p1.monochrome[5],
    boxShadow: p1.monochrome[5],
    iconColor: p1.teal[0],
    buttonColor: p1.monochrome[0],
    footerBg: p1.monochrome[1],
    footerIconFill: p1.teal[0],
    footerLabelHover: typography.type.secondary,
    fontFamilyHover: typography.type.tertiary,
  },

  customerStructure: {
    font: {
      size: typography.sizesSet.r9,
    },
    color: p1.monochrome[3],
    statusColor: {
      ok: p1.chateauGreen[0],
      deleted: p1.alizarinCrimson[1],
      locked: p1.monochrome[3],
    },
    button: {
      color: p1.teal[0],
      background: p1.monochrome[0],
      addUserFontSize: typography.sizesSet.r10,
      dotColor: p1.blazeOrange[0],
    },
    accessList: {
      title: {
        color: p1.teal[0],
        background: p1.monochrome[0],
      },
      errorMessageColor: p1.alizarinCrimson[0],
    },
  },

  scrolledHeader: {
    actionsMainAccent: p1.monochrome[0],
    desktopStickyItemsOffset: 75,
    mobileStickyItemsOffset: 61,
  },
  animations,
  breakpoints,
  spacing,
  transitions,
  typography,
});

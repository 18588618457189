import { useState } from 'react';
import clientCookieManager from 'js-cookie';
import { CookieContext } from './CookieContext';

const defaultProps = {
  cookies: {},
};

export const CookiesProvider = ({ children, cookies: initialState } = defaultProps) => {
  const [cookies, setCookies] = useState(() => initialState);

  const setCookie = (cookieName, cookieValue, options = {}) => {
    clientCookieManager.set(cookieName, cookieValue, options);
    setCookies((currentCookies) => ({ ...currentCookies, [cookieName]: cookieValue }));
  };

  return (
    <CookieContext.Provider
      value={{
        cookies,
        setCookie,
      }}
    >
      {children}
    </CookieContext.Provider>
  );
};
